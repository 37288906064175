import * as React from 'react';
import { graphql } from 'gatsby';
import {
  AuthUserContextType,
  Database,
  LocationLanguageContextType,
  LogInResponse,
  NovadeLiteUrlContextType,
  DashboardProps,
  StrapiDashboardPage
} from 'typings';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { StaticImage } from 'gatsby-plugin-image';
import Snackbar from '@material-ui/core/Snackbar';
import DashboardImagePreview from '../components/Dashboard/ImagePreviewDialog';
import { NovadeLiteUrlContext } from '../contexts/NovadeLiteUrlContext';
import Seo from '../components/Seo';
import { AuthUserContext } from '../contexts/AuthUserContext';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { Languages } from '../utils/getAllSupportedLanguages';
import WorkspaceDialog from '../components/WorkspaceDialog';
import LoginDialog from '../components/LoginDialog';
import useUpsertDashboard from '../hooks/useUpsertDashboard';
import { matchesMobile } from '../utils/mediaQuery';
import useDashboardStyles from '../styles/Dashboard';
import DashboardActionButtonsWeb from '../components/Dashboard/ActionButtonsWeb';
import LinkedTemplates from '../components/Dashboard/LinkedTemplates';
import DashboardInfoSection from '../components/Dashboard/DashboardInfoSection';
import DashboardActionButtonMobile from '../components/Dashboard/ActionButtonsMobile';
import DashboardImagePreviewMobile from '../components/Dashboard/ImagePreviewDialogMobile';

export const query = graphql`
  query DashboardQuery($id: Int!) {
    strapiDashboard(strapiId: { eq: $id }) {
      id
      strapiId
      author
      title
      slug
      imageUrl
      description
      published_at
      dashboard {
        name
        widgets {
          filters {
            actionRequiredBy
            project
            template
            creator
            status
            formTag {
              name
              color
            }
            templateTag {
              name
              color
            }
          }
          name
          sets {
            type
            rank
            name
            key {
              id
              type
            }
          }
          source
          type
        }
      }
      id
      templates {
        id
        Slug
        Title
        Author
        published_at
        Template {
          name
          templateGroup {
            name
          }
          startStepID
          templateSections {
            name
            rank
            sectionFields {
              name
              rank
              extra {
                formula
                galleryAllowed
                textValidation
                usedForScore
                score
              }
              isMandatory
              fieldInputType
              dataMappingKeyID
              showInPdfExport
              linkedWorkflowStepIDs
              galleryAllowed
              textValidation
              selectOptions {
                color
                name
                rank
                score
              }
              subFields {
                fieldInputType
                name
                rank
                selectOptions {
                  color
                  name
                  rank
                }
                extra {
                  calculation
                  formula
                  galleryAllowed
                  textValidation
                }
              }
            }
          }
          workflowActions {
            fromStepID
            toStepID
          }
          workflowSteps {
            color
            id
            name
            phase
            x
            y
            dataMappingKeyID
            options {
              note {
                defaultValue
              }
              requiresSignature
              requiresExplanation
            }
          }
          tags {
            color
            name
          }
        }
      }
    }
    allStrapiDashboardPage {
      nodes {
        actionButton
        authorLabel
        longText
        publishDateLabel
        text
        successMessage
        errorMessage
        workspacePermissionMessage
        locale
      }
    }
  }
`;

const DashboardPage: React.FC<DashboardProps> = ({ data }) => {
  const classes = useDashboardStyles();
  const { strapiDashboard, allStrapiDashboardPage } = data;
  const { templates } = strapiDashboard;
  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const authUser = React.useContext<AuthUserContextType>(AuthUserContext);
  const contextUrl = React.useContext<NovadeLiteUrlContextType>(NovadeLiteUrlContext);
  const databasesMap = authUser ? Object.values(authUser.databasesMap)[0] : {};
  const workspaces = databasesMap ? Object.values(databasesMap) : [];
  const language = locationLanguage ? locationLanguage.language : Languages.English;
  const [targetWorkspace, setTargetWorkspace] = React.useState<Database | undefined>(workspaces.length ? workspaces[0] : undefined);
  const [openWorkspace, setOpenWorkspace] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState<string | undefined>('');
  const [openImagePreview, setOepnImagePreview] = React.useState(false);
  const dashboardPageContent = allStrapiDashboardPage.nodes.find((x: StrapiDashboardPage) => x.locale === language);
  const isMobile = matchesMobile();
  const alignment = isMobile ? 'center' : 'left';

  React.useEffect(() => {
    const newWorkspace = workspaces.length ? workspaces[0] : undefined;
    setTargetWorkspace(newWorkspace);
  }, [workspaces.length]);

  const openLoginDialog = () => {
    setOpenLogin(true);
  };

  const openWorkspaceDialog = () => {
    setOpenWorkspace(true);
  };

  const handleSnackbarClose = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSnackbarOpen = (status: boolean) => {
    const message = status ? dashboardPageContent?.successMessage : dashboardPageContent?.errorMessage;
    setSnackBarMsg(message);
    setOpenSnackbar(true);
  };

  const { upsertDashboard, loading } = useUpsertDashboard(contextUrl.novadeLiteUrl, targetWorkspace, strapiDashboard, handleSnackbarOpen);

  const handleLoginDialogClose = (value: LogInResponse | undefined) => {
    if (value) {
      authUser?.updateUser({
        email: value.email,
        displayName: value.userDisplayName
      });
      authUser?.updateDatabasesMap(value.databases);
    }
    setOpenLogin(false);
  };

  const handleDashboardSaving = () => {
    if (targetWorkspace) upsertDashboard();
  };

  const onSaveToNovadeLite = () => {
    if (authUser.user) {
      if (workspaces.length === 1) {
        upsertDashboard();
      } else if (workspaces.length > 1) openWorkspaceDialog();
      else {
        setSnackBarMsg(dashboardPageContent?.workspacePermissionMessage);
        setOpenSnackbar(true);
      }
    } else openLoginDialog();
  };

  const handleWorkspaceUpdate = (newWorkspace: Database) => {
    setTargetWorkspace(newWorkspace);
  };

  const handleWorkspaceDialogClose = (isSharingTriggered: boolean | undefined) => {
    if (isSharingTriggered) handleDashboardSaving();
    setOpenWorkspace(false);
  };

  const handleImagePreviewClose = () => {
    setOepnImagePreview(false);
  };

  const handleImagePreview = () => {
    setOepnImagePreview(true);
  };

  if (dashboardPageContent && strapiDashboard) {
    return (
      <>
        <Seo title={strapiDashboard.title} description={strapiDashboard.description} keywords={strapiDashboard.keywords} isTemplatePage />
        <Container maxWidth='xl' style={{ marginTop: '2rem' }}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={5} className={classes.imagePreview}>
              <StaticImage
                layout='fullWidth'
                placeholder='tracedSVG'
                src='../assets/DesktopGraphic.png'
                alt={strapiDashboard.title}
                onClick={handleImagePreview}
              />
            </Grid>
            <Grid item xs={12} md={7} className={classes.infoSection}>
              <Grid container direction='row'>
                <DashboardInfoSection strapiDashboard={strapiDashboard} dashboardPageContent={dashboardPageContent} isMobile={isMobile} />
                {isMobile ? null : (
                  <Grid item md={3}>
                    <DashboardActionButtonsWeb
                      dashboardPageContent={dashboardPageContent}
                      onSaveToNL={onSaveToNovadeLite}
                      loading={loading}
                    />
                  </Grid>
                )}
              </Grid>
              {strapiDashboard.description ? (
                <Grid container direction='row'>
                  <Grid item xs={12}>
                    <Typography variant='body1' align={alignment} className={`${classes.marginBottom} ${classes.templateBodyText}`}>
                      {strapiDashboard.description}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {isMobile ? null : <LinkedTemplates templates={templates} dashboardPageContent={dashboardPageContent} isMobile={isMobile} />}
            </Grid>
          </Grid>
          {isMobile ? <LinkedTemplates templates={templates} dashboardPageContent={dashboardPageContent} isMobile={isMobile} /> : null}
          {isMobile ? (
            <DashboardActionButtonMobile dashboardPageContent={dashboardPageContent} onSaveToNL={onSaveToNovadeLite} loading={loading} />
          ) : null}
        </Container>
        {workspaces.length > 1 ? (
          <WorkspaceDialog
            open={openWorkspace}
            onDialogClose={handleWorkspaceDialogClose}
            workspaces={databasesMap}
            onWorkspaceChange={handleWorkspaceUpdate}
          />
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={openSnackbar}
          onClose={handleSnackbarClose}
          autoHideDuration={5000}
          message={snackBarMsg}
        />
        <LoginDialog open={openLogin} onDialogClose={handleLoginDialogClose} />
        {isMobile ? (
          <DashboardImagePreviewMobile open={openImagePreview} onDialogClose={handleImagePreviewClose} />
        ) : (
          <DashboardImagePreview open={openImagePreview} img={strapiDashboard.imageUrl} onDialogClose={handleImagePreviewClose} />
        )}
      </>
    );
  }

  return <Typography variant='body1' />;
};

export default DashboardPage;
